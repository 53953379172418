<template>
    <PageLayout>
        <template>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center flex-grow-1">
                    <h1>
                        <template v-if="currentFilter">
                            {{ currentFilter.name }}
                        </template>
                        <template v-else>
                            {{ $t('tasks') }}
                        </template>
                    </h1>
                    <TableSettings
                        :fields="tableColumns"
                        :entityName="entityName"
                        className="ml-2"
                        @column-select="onColumnSelect"
                    />
                </div>
                <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="4" v-show="!filter_show" class="text-right">
                    <v-icon :disabled="userBlock" v-if="can(['administrator'])" class="mr-2" color="primary" @click="$router.push({name: 'task'})">
                        mdi-view-headline
                    </v-icon>
                    <v-icon :disabled="userBlock" v-if="can(['administrator'])" class="mr-5" color="primary" @click="$router.push({name: 'task.kanban'})">
                        mdi-format-columns
                    </v-icon>
                </v-col>
                <div class="text-right">
                    <Btn
                        v-if="$vuetify.breakpoint.smAndUp"
                        :disabled="userBlock"
                        :to="{name: 'task.create'}"
                        color="secondary"
                    >
                        {{ $t('task.add') }}
                    </Btn>
                    <Teleport to="#app" v-else>
                        <div class="fab__wrapper">
                            <Btn
                                fab
                                :disabled="userBlock"
                                :to="{name: 'task.create'}"
                                :title="$t('task.add')"
                                color="secondary"
                            ><v-icon>mdi-plus</v-icon></Btn>
                        </div>
                    </Teleport>
                </div>
            </div>

            <div class="mobile-filter mb-5" v-if="$vuetify.breakpoint.smAndDown">
                <v-icon
                    class="mobile-filter__btn mr-2"
                    :disabled="userBlock"
                    color="secondary"
                    @click="filter_show === true ? filter_show = false : filter_show = true"
                    :title="$t['filter_add']"
                >mdi-filter-plus</v-icon>

                <FilterMobileLinks
                    :links="[
                        ...Additional.map((item) => {
                            return {
                                ...item
                            }
                        }),

                        ...system_filters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),

                        ...userFilters.map((item) => {
                            return {
                                ...item,
                                title: item.name
                            }
                        }),
                    ]"
                />
            </div>

            <FilterComponent
                v-if="filter_show"
                :filterData="filterData"
                :currentFilter="currentFilter"
                :value="filterValues"
                :loading="loading"
                :invalid="invalid"
                entity="task"
                @input="filterValues = $event"
                @submit="submitForm"
                @filterSaved="filterSaved"
                @filterDeleted="filterDeleted"
            />

            <template v-if="$vuetify.breakpoint.smAndDown">
                <ListItem
                    v-for="item in tasks"
                    :item="item"
                    :key="item.id"
                    :columns="tableColumns"
                    :userBlock="userBlock"

                    @deleteItem="deleteTask(item)"
                >
                    <template v-slot:title="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            <v-hover v-slot="{ hover }">
                                <div>
                                    <v-btn text plain :to="showTask(item)" class="cursor-pointer font_weight_500 button_link ">
                                        {{ item.title.replace(new RegExp(item.number + '. ', 'g'), '') }}
                                    </v-btn>
                                    <v-icon color="red" v-if="item.is_urgent && (item.task_status_id !== 3 && item.task_status_id !== 5)" :title="$t('edit')">
                                        mdi-fire mdi-18px
                                    </v-icon>
                                    <v-icon v-if="hover" @click="editTask(item)" class="ml-2" :title="$t('edit')">
                                        mdi-pencil mdi-18px
                                    </v-icon>
                                    <v-icon v-if="hover && can(['administrator', 'director', 'general_manager'])" @click="deleteTask(item)" :title="$t('delete')">
                                        mdi-delete-outline mdi-18px
                                    </v-icon>
                                </div>
                            </v-hover>
                        </div>
                    </template>

                    <template v-slot:number="{ item }">
                        <v-btn text plain :to="showTask(item)" class="cursor-pointer font_weight_500 button_link ">
                            {{ item.number }}
                        </v-btn>
                        <v-icon color="red" v-if="item.deadline_to_end" :title="$t('deadline_is_coming_to_an_end')" class="mb-2">mdi-clock-time-three-outline  mdi-18px</v-icon>
                    </template>

                    <template v-slot:task_status="{ item }">
                        <div :style="'color:'+ item.color+'!important'">{{ item.task_status }}</div>
                    </template>

                    <template v-slot:created_at="{ item }">
                        <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                    </template>

                    <template v-slot:task_status_id="{ item }">
                        <div>
                            {{ item.task_status }}
                        </div>
                    </template>

                    <template v-slot:task_type_id="{ item }">
                        <div>
                            {{
                                taskTypes.find((type) => {
                                    return type.id === item.task_type_id
                                }).name
                            }}
                        </div>
                    </template>
                    <template v-slot:uved_id="{ item }">
                        <div v-if="item.uved">
                            <v-btn text plain :to="'/uved/' +  item.uved.uuid + '/show'" class="button_link cursor-pointer font_weight_500">
                                {{ item.uved.company_name ? item.uved.company_name : ( item.uved.first_name + ' ' + item.uved.last_name ) }}
                            </v-btn>
                        </div>
                    </template>

                    <template v-slot:deadline="{ item }">
                        <div>{{ item.deadline ? $moment(item.deadline).format('DD.MM.YYYY HH:mm') : '' }}</div>
                    </template>

                    <template v-slot:admin_id="{ item }">
                        <div>
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar size="36" class="my-0 py-0" :class="{'border_blue': item.admin.photo === '/img/avatar.png'}">
                                        <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>

                    <template v-slot:auditors="{ item }">
                        <div v-if="item.auditors && item.auditors.length === 1 && item.auditors[0] && item.auditors[0].name">
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar  size="36" class="my-0 py-0 cursor-pointer" >
                                        <img v-if="item.auditors[0].photo !== '/img/avatar.png'" :src="item.auditors[0].photo" :alt="item.auditors[0].name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{'settingsIcon'}}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.auditors[0].name" ></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.auditors[0].phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>

                        <div v-if="item.auditors && item.auditors.length > 1">
                            <div class="avatars cursor-pointer">
                                <span class="avatar" v-for="(item, index) in item.auditors"  :key="index">
                                      <v-avatar size="36" >
                                          <img v-if="item.photo !== '/img/avatar.png'" :title="item.name" class="" :src="item.photo" :alt="item.name">
                                          <img v-else :title="item.name" class="avatar_border avatar_border_prime" src="/img/avatar.svg" :alt="item.name">
                                      </v-avatar>
                                </span>
                            </div>
                        </div>
                    </template>

                    <template v-slot:executors="{ item }">
                        <div v-if="item.executors && item.executors.length === 1 && item.executors[0] && item.executors[0].name">
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar  size="36" class="my-0 py-0 cursor-pointer" >
                                        <img v-if="item.executors[0].photo !== '/img/avatar.png'" :src="item.executors[0].photo" :alt="item.executors[0].name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{'settingsIcon'}}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.executors[0].name" ></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.executors[0].phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>

                        <div v-if="item.executors && item.executors.length > 1">
                            <div class="avatars cursor-pointer">
                                <span class="avatar" v-for="(item, index) in item.executors"  :key="index">
                                      <v-avatar size="36" >
                                          <img v-if="item.photo !== '/img/avatar.png'" :title="item.name" class="" :src="item.photo" :alt="item.name">
                                          <img v-else :title="item.name" class="avatar_border avatar_border_prime" src="/img/avatar.svg" :alt="item.name">
                                      </v-avatar>
                                </span>
                            </div>
                        </div>
                    </template>
                </ListItem>
            </template>
            <template v-else>
                <ResizableTable
                    class="main-data-table elevation-0 uveds-table"
                    :rows="tasks"
                    :columns="tableColumns"
                    :sort-by="options.sortBy[0]"
                    :sort-dir="options.sortDesc[0]"

                    @sort="sortItems"
                    @columnMoved="onColumnsMoved"
                    @columnResized="onColumnResized"
                >

                    <template v-slot:title="{ item }">
                        <div :class="{'red--text': item.deleted, 'overdue': item.overdue}">
                            <v-hover v-slot="{ hover }">
                                <div>
                                    <v-btn text plain :to="showTask(item)" class="cursor-pointer font_weight_500 button_link" :style="item.overdue ? 'color: red' : 'color:'+ item.color">
                                        {{ item.title.replace(new RegExp(item.number + '. ', 'g'), '') }}
                                    </v-btn>
                                    <v-icon color="red" v-if="item.is_urgent && (item.task_status_id !== 3 && item.task_status_id !== 5)" :title="$t('edit')">
                                        mdi-fire mdi-18px
                                    </v-icon>
                                    <v-icon v-if="hover" @click="editTask(item)" class="ml-2" :title="$t('edit')">
                                        mdi-pencil mdi-18px
                                    </v-icon>
                                    <v-icon v-if="hover && can(['administrator', 'director', 'general_manager'])" @click="deleteTask(item)" :title="$t('delete')">
                                        mdi-delete-outline mdi-18px
                                    </v-icon>
                                </div>
                            </v-hover>
                        </div>
                    </template>

                    <template v-slot:number="{ item }">
                        <v-btn :class="{'overdue': item.overdue}" text plain :to="showTask(item)" class="cursor-pointer font_weight_500 button_link " :style="item.overdue ? 'color: red' : 'color:'+ item.color">
                            {{ item.number }}
                        </v-btn>

                        <v-tooltip right v-if="item.deadline_to_end">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="red"  v-bind="attrs" v-on="on" class="mb-1">mdi-clock-time-three-outline mdi-18px</v-icon>
                            </template>
                            <span>{{$t('deadline_is_coming_to_an_end')}}</span>
                        </v-tooltip>


                        <v-tooltip right  v-if="item.extend_deadline && item.extend_deadline.uuid">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="primary"  v-bind="attrs" v-on="on" class="mb-1">mdi-comment-processing-outline mdi-18px</v-icon>
                            </template>
                            <span>{{$t('request_to_reschedule_deadline')}}: <br /> {{item.extend_deadline.reason}}</span>
                        </v-tooltip>

                      </template>

                    <template v-slot:messages="{ item }">
                        <div class="message-budges">
                            <span class="message-budge" v-if="item.unread_messages_count > 0">
                                <v-icon>icon icon-messages</v-icon>
                            </span>
                            <span class="message-budge message-budge--with_mentioning" v-if="item.unread_messages_with_mentioning_count > 0">
                                <v-icon>mdi mdi-at</v-icon>
                            </span>
                            <span class="message-budge" v-if="item.notification_count > 0">
                                <v-icon>icon-notifications</v-icon>
                            </span>
                        </div>
                    </template>

                    <template v-slot:task_status_id="{ item }">
                        <div :style="item.overdue ? 'color: red' : 'color:' + item.color">{{ item.task_status }}</div>
                    </template>

                    <template v-slot:created_at="{ item }">
                        <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                    </template>

                    <template v-slot:task_type_id="{ item }">
                        <div :style="item.overdue ? 'color: red' : 'color:'+ item.color">
                            {{
                                taskTypes.find((type) => {
                                    return type.id === item.task_type_id
                                }).name
                            }}
                        </div>
                    </template>

                    <template v-slot:uved_id="{ item }">
                        <div v-if="item.uved">
                            <v-btn text plain :to="'/uved/' +  item.uved.uuid + '/show'" class="button_link cursor-pointer font_weight_500">
                                {{ item.uved.company_name ? item.uved.company_name : ( item.uved.first_name + ' ' + item.uved.last_name ) }}
                            </v-btn>
                        </div>
                    </template>

                    <template v-slot:deadline="{ item }">
                        <div>{{ item.deadline ? $moment(item.deadline).format('DD.MM.YYYY HH:mm') : '' }}</div>
                    </template>

                    <template v-slot:admin_id="{ item }">
                        <div>
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar size="36" class="my-0 py-0" :class="{'border_blue': item.admin.photo === '/img/avatar.png'}">
                                        <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>

                    <template v-slot:responsibles="{ item }">
                        <div v-if="item.responsibles && item.responsibles.length === 1 && item.responsibles[0] && item.responsibles[0].name">
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar  size="36" class="my-0 py-0 cursor-pointer" >
                                        <img v-if="item.responsibles[0].photo !== '/img/avatar.png'" :src="item.responsibles[0].photo" :alt="item.responsibles[0].name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{'settingsIcon'}}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.responsibles[0].name" ></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.responsibles[0].phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>

                        <div v-if="item.responsibles && item.responsibles.length > 1">
                            <div class="avatars cursor-pointer">
                                <span class="avatar" v-for="(item, index) in item.responsibles"  :key="index">
                                      <v-avatar size="36" >
                                          <img v-if="item.photo !== '/img/avatar.png'" :title="item.name" class="" :src="item.photo" :alt="item.name">
                                          <img v-else :title="item.name" class="avatar_border avatar_border_prime" src="/img/avatar.svg" :alt="item.name">
                                      </v-avatar>
                                </span>
                            </div>
                        </div>
                    </template>

                    <template v-slot:auditors="{ item }">
                        <div v-if="item.auditors && item.auditors.length === 1 && item.auditors[0] && item.auditors[0].name">
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar  size="36" class="my-0 py-0 cursor-pointer" >
                                        <img v-if="item.auditors[0].photo !== '/img/avatar.png'" :src="item.auditors[0].photo" :alt="item.auditors[0].name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{'settingsIcon'}}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.auditors[0].name" ></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.auditors[0].phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>

                        <div v-if="item.auditors && item.auditors.length > 1">
                            <div class="avatars cursor-pointer">
                                <span class="avatar" v-for="(item, index) in item.auditors"  :key="index">
                                      <v-avatar size="36" >
                                          <img v-if="item.photo !== '/img/avatar.png'" :title="item.name" class="" :src="item.photo" :alt="item.name">
                                          <img v-else :title="item.name" class="avatar_border avatar_border_prime" src="/img/avatar.svg" :alt="item.name">
                                      </v-avatar>
                                </span>
                            </div>
                        </div>
                    </template>

                    <template v-slot:executors="{ item }">
                        <div v-if="item.executors && item.executors.length === 1 && item.executors[0] && item.executors[0].name">
                            <v-list subheader two-line class="background_none py-0">
                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                    <v-list-item-avatar  size="36" class="my-0 py-0 cursor-pointer" >
                                        <img v-if="item.executors[0].photo !== '/img/avatar.png'" :src="item.executors[0].photo" :alt="item.executors[0].name">
                                        <v-icon v-else color="primary" size="22">
                                            ${{'settingsIcon'}}
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="py-0 title_subtitle">
                                        <v-list-item-title v-text="item.executors[0].name" ></v-list-item-title>
                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.executors[0].phone)"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>

                        <div v-if="item.executors && item.executors.length > 1">
                            <div class="avatars cursor-pointer">
                                <span class="avatar" v-for="(item, index) in item.executors"  :key="index">
                                      <v-avatar size="36" >
                                          <img v-if="item.photo !== '/img/avatar.png'" :title="item.name" class="" :src="item.photo" :alt="item.name">
                                          <img v-else :title="item.name" class="avatar_border avatar_border_prime" src="/img/avatar.svg" :alt="item.name">
                                      </v-avatar>
                                </span>
                            </div>
                        </div>
                    </template>

                </ResizableTable>
            </template>

            <div class="main-pagination d-flex flex-row justify-end">
                <SelectInput
                    class="main-pagination__per-page"
                    id="pagination-variants"
                    :value="options.itemsPerPage"
                    :items="perPageItems"
                    :label="$t('items_per_page')"
                    :disabled="loading"
                    @input="onPerPageChange($event)"
                    hide-label
                ></SelectInput>
                <v-pagination v-model="page" :total-visible="totalVisiblePag" class="main-pagination__pagination pagination_table" :length="pageCount" :disabled="loading" @input="onPageChange"></v-pagination>
            </div>
        </template>

        <template v-slot:side>
            <v-icon v-if="$vuetify.breakpoint.mdAndUp" class="mt-2 mb-5" :disabled="userBlock" color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true" :title="$t['filter_add']">mdi-filter-plus</v-icon>

            <v-list :disabled="userBlock" class="filter_left" v-if="Additional.length > 0 && $vuetify.breakpoint.mdAndUp">
                <v-list-group :ripple="false" v-model="group2" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" :to="item.url" :class="{'active-item': isSameRouteWithQueryParams($route, item.url)}">
                        <v-list-item-title v-if="item.can" v-text="item.title" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-if="item.can" v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list :disabled="userBlock" class="filter_left" v-if="adminItems.length > 0 && can(['administrator']) && $vuetify.breakpoint.mdAndUp">
                <v-list-group :ripple="false" v-model="group3" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter mb-2">{{ $t('subordinates') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in adminItems" :key="i" :to="item.url" :class="item.id === admin ? 'active' : ''">
                        <v-list-item-title v-text="item.name" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon class="filter_count"> 0</v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list :disabled="userBlock" class="filter_left" v-if="$vuetify.breakpoint.mdAndUp">
                <v-list-group :ripple="false" v-model="group1" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('additional') }}</v-list-item-title>
                    </template>

                    <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" link :to="item.url" :class="item.id === task_status ? 'active' : ''">
                        <v-list-item-title v-text="item.name" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon  v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-list :disabled="userBlock" v-if="userFilters.length > 0 && $vuetify.breakpoint.mdAndUp" class="filter_left">
                <v-list-group :ripple="false" v-model="userFiltersGroup" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('user_filters') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in userFilters" :key="i" :class="{'active-item': isSameRouteWithQueryParams($route, item.url)}" :active-class="'no-active'" :to="item.url">
                        <v-list-item-title v-text="item.name" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
    </PageLayout>
</template>

<script>
import { mapGetters } from "vuex"
import FilterView from "@/plugins/mixins/FilterView";
import PageLayout from "@/components/Leentech/PageLayout.vue";
import Btn from "@/components/Form/Btn.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import ResourceView from "@/plugins/mixins/ResourceView";
import NavMixin from "@/plugins/mixins/Nav";
import ListItem from "@/components/Task/ListItem.vue";
import FilterMobileLinks from "@/components/FilterMobileLinks.vue";
import Teleport from "vue2-teleport"

export default {
    name: "Tasks",
    mixins: [
        FilterView,
        ResourceView,
        NavMixin
    ],
    components: {
        FilterMobileLinks,
        ListItem,
        Btn,
        PageLayout,
        SelectInput,
        Teleport
    },
    data() {
        return {
            entityName: 'task',
            invalid: false,

            options: {
                ...FilterView.data().options,
                sortBy: ['id']
            },

            date: null,
            dateDialog: false,
            number: null,
            title: null,
            admin: null,
            adminItems: [],
            task_status: null,
            task_statusItems: [],
            fab: false,
            task: null,
            tasks: [],
            taskItems: [],
            sortBy: "created_at",
            sortDir: false,
            page: 1,
            pageCount: 0,
            totalVisiblePag: 7,
            totalTasks: 0,
            loading: false,
            headers: [
                {
                    text: this.$t('task_name'),
                    align: "left",
                    sortable: true,
                    value: "title"
                },
                {
                    text: '№',
                    align: "left",
                    sortable: true,
                    value: "number"
                },
                {
                    text: this.$t('task_status'),
                    sortable: false,
                    value: "task_status"
                },
                {
                    text: this.$t('deadline'),
                    sortable: true,
                    value: "deadline"
                },
                // {
                //     text: this.$t('date'),
                //     sortable: true,
                //     value: "created_at"
                // },
                {
                    text: this.$t('responsibles'),
                    align: "left",
                    sortable: false,
                    value: "responsibles"
                },
                {
                    text: this.$t('author'),
                    align: "left",
                    sortable: false,
                    value: "admin.name"
                },
            ],
            active: null,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 2,
                    name: this.$t('no')
                }
            ],
            system_filters: [
                {'title': 'Все контакты', 'count': '10'},
                {'title': 'Мои клиенты', 'count': '0'},
                {'title': 'Избранное', 'count': '7'},
            ],
            action_filter: 'all',
            Additional: [
                {
                    'title': this.$t('my_tasks'),
                    'count': '-',
                    'action': 'all',
                    'can': 1,
                    'url': {
                        path: '/task',
                        query: {
                            filter: {
                                task_status_id: {
                                    notin: ['3', '5']
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('inbox'),
                    'count': '-',
                    'action': 'inbox',
                    'can': 1,
                    'url': {
                        path: '/task',
                        query: {
                            filter: {
                                inbox: {
                                    custom: '1'
                                },
                                task_status_id: {
                                    notin: ['3', '5']
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('outbox'),
                    'count': '-',
                    'action': 'outbox',
                    'can': 1,
                    'url': {
                        path: '/task',
                        query: {
                            filter: {
                                outbox: {
                                    custom: '1'
                                },
                                task_status_id: {
                                    notin: ['3', '5']
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('favorite'),
                    'count': '-',
                    'action': 'favorite',
                    'can': 1,
                    'url': {
                        path: '/task',
                        query: {
                            filter: {
                                favorite: {
                                    custom: '1'
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('overdue'),
                    'count': '-',
                    'action': 'overdue',
                    'can': 1,
                    'url': {
                        path: '/task',
                        query: {
                            filter: {
                                deadline: {
                                    lte: this.$moment().format('YYYY-MM-DD')
                                },
                                task_status_id: {
                                    in: ['1', '2']
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('urgent'),
                    'count': '-',
                    'action': 'urgent',
                    'can': 1,
                    'url': {
                        path: '/task',
                        query: {
                            filter: {
                                is_urgent: {
                                    eq: '1'
                                },
                                task_status_id: {
                                    notin: ['3', '5']
                                }
                            }
                        }
                    }
                },
                {
                    'title': this.$t('subordinates'),
                    'count': '-',
                    'action': 'admin_all',
                    'can': this.can(['director', 'administrator']),
                    'url': {
                        path: '/task',
                        query: {
                            filter: {
                                all_tasks: {
                                    eq: '1'
                                },
                                task_status_id: {
                                    notin: [
                                        '3',
                                        '5',
                                        '7'
                                    ]
                                }
                            }
                        }
                    }
                },
            ],
            group1: true,
            group2: true,
            group3: true,
            userFiltersGroup: true,
            filter_show: false,
            taskTypes: []
        };
    },

    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'userBlock']),

        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            if (this.userBlock === true) {
                this.$router.push({
                    path: '/task',
                    query: {
                        filter: {
                            deadline: {
                                lte: this.$moment().format('YYYY-MM-DD')
                            },
                            task_status_id: {
                                in: ['1', '2']
                            }
                        }
                    }
                })

                return
            }

            await this.getItems();
            await this.getTaskStatuses()
            await this.fetchTaskTypes();
            await this.getTaskCounts();
            await this.getAdmins();
            await this.getFilters();
        },

        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5_Task' : 'rowMobiClass';
        },

        async fetchTaskTypes() {
            this.taskTypes = await this.$http.get('admin/task_type')
                .then(res => {
                    return res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_task_types'))
                })
        },

        sendRoute(item){
            this.admin = null;
            this.task_status = item.id;
            this.getTasks();
        },

        canSort(column) {
            // Здесь вы можете определить, можно ли сортировать столбец
            // Например, можете вернуть true для всех столбцов, которые могут быть сортированы
            return true;
        },

        sortItems(column) {
            if (this.$route.query.sortBy === column) {
                let sortDir = this.$route.query.sortDir === 'true';

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        sortDir: sortDir ? 'false' : 'true'
                    }
                });
            } else {
                this.$router.push({
                    query: {
                        ...this.$route.query,
                        sortBy: column,
                        sortDir: false
                    }
                });
            }
            // Здесь вы можете отсортировать элементы items
            // в соответствии с выбранным столбцом и порядком сортировки (asc/desc)
        },

        showTask(item) {
            return {
                name: 'task.show',
                params: {
                    id: item.uuid
                }
            }
        },

        editTask(item) {
            this.$router.push({
                name: 'task.edit',
                params: {
                    id: item.uuid
                }
            })
        },

        async deleteTask(item) {
            if (confirm(this.$t('delete_task'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/task/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('task_has_been_deleted'))
                        this.getTasks()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('task_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        getItems() {
            this.getTasks()
        },

        async getTasks(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }

            params.sortDir = 'asc';
            if(sortDesc) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }

            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }

            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            if (this.number) {
                params.number = this.number;
            }
            if (this.title) {
                params.title = this.title;
            }

            if (this.date) {
                params.date = this.date;
            }

            if (this.action_filter === 'admin_all') {
                params.task_status = -3;
            }

            if (this.task_status) {
                params.task_status = this.task_status;
            }

            if (this.admin) {
                if (this.admin.id) {
                    params.admin = this.admin.id
                } else {
                    params.admin = this.admin
                }
            }

            if (this.action_filter === 'inbox') {
                params.inbox = 1;
            }
            if (this.action_filter === 'outbox') {
                params.outbox = 1;
            }
            if (this.action_filter === 'favorite') {
                params.favorite = 1;
            }
            if (this.action_filter === 'overdue') {
                params.overdue = 1;
            }
            if (this.action_filter === 'urgent') {
                params.urgent = 1;
            }


            if (this.filterValues) {
                params.fltr = this.filterValues;
            }

            if (this.active === -1 || this.active === 1 || this.active === 0) {
                params.active = this.active
            }

            await this.$http
                .get("admin/task", {
                    params: params,
                })
                .then(res => {
                    this.filterData = res.body.filter
                    this.tasks = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalTasks = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.tasks = []
                    this.totalTasks = 0
                    this.$toastr.error(this.$t('failed_to_get_list_tasks'))
                })
                .finally(end => {
                    this.loading = false
                })
        },

        async getTaskStatuses() {
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/task_status`, {
                    params: params,
                })
                .then(res => {
                    this.task_statusItems = res.body.data
                    this.system_filters = res.body.data.map((item) => {
                        return {
                            ...item,
                            title: item.name,
                            url: {
                                path: '/task',
                                query: {
                                    filter: {
                                        task_status_id: {
                                            eq: item.id + ''
                                        }
                                    }
                                }

                            }
                        }
                    })
                })
                .catch(err => {
                    this.task_statusItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_task_statuses'))
                });
        },

        async getAdmins() {
            let params = {};
            params.filter = 'all';
            params.active = 1;
            await this.$http
                .get(`admin/admin`, {
                    params: params,
                })
                .then(res => {
                    this.adminItems = res.body.data.map((item) => {
                        return {
                            ...item,
                            url: {
                                path: '/task',
                                query: {
                                    filter: {
                                        admin_id: {
                                            eq: item.id + ''
                                        }
                                    }
                                }
                            }
                        };
                    });
                })
                .catch(err => {
                    this.adminItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_admins'))
                });
        },

        async getTaskCounts() {
            let params = {};
            await this.$http
                .get(`admin/task_counts`, {
                    params: params,
                })
                .then(res => {
                    if(res && res.data && res.data.data){
                        Object.keys(res.data.data).forEach(key => {
                            const actionItem = this.Additional.find(item => item.action === key);
                            const actionItem2 = this.system_filters.find(item => item.action === key);
                            if (actionItem) {
                                actionItem.count = res.data.data[key];
                            }
                            else if (actionItem2) {
                                actionItem2.count = res.data.data[key];
                            }
                        });
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_counts'))
                });
        },

        async activeTask(item) {

            var _this = this

            this.loading = true;
            let params = {};
            if (item.active) {
                params.active = 1
            } else {
                params.active = 0
            }
            await this.$http
                .put(`admin/task/active/${item.uuid}`, params)
                .then(res => {
                    if (params.active) {
                        this.$toastr.success(this.$t('task_is_open'))
                    } else {
                        this.$toastr.success(this.$t('task_is_closed'))
                    }

                })
                .catch(err => {
                    if (params.active) {
                        item.active = false;
                        this.$toastr.error(this.$t('task_was_not_open'))
                    } else {
                        this.$toastr.error(this.$t('task_was_not_closed'))
                    }
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })

                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
